
import ResponsePage from "./ResponsePage";

const Tables = () => {
  return (
    <>
      {/* <TableOne/>
      <TableTwo/> */}
      {/* <TableThree/> */}
      <ResponsePage></ResponsePage>
    </>
  );
};

export default Tables;
